var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Navigation',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" The component "),_c('code',[_vm._v("<b-navbar>")]),_vm._v(" is a wrapper that positions branding, navigation, and other elements into a concise header. It's easily extensible and thanks to the "),_c('code',[_vm._v("<b-collapse>")]),_vm._v(" component, it can easily integrate responsive behaviors. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('text-content')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('custom')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }